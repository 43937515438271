.searchBox{
  width: 55%;
  height:5%;
  margin-left: 22.5%;
  margin-left: 22.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-style: solid;
  border-width: medium;
  border-color: rgb(145, 114, 71);
}
