.Fbook-icon{
 background-image: url(/advertisingimages/Facebook.png);
 background-size: cover;
 height: 30px;
 width:30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Fbook-icon:hover{
  transform: scale(1.5);
}

.Insta-icon{
 background-image: url(/advertisingimages/Instagram.png);
 background-size: cover;
 height: 30px;
 width: 30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Insta-icon:hover{
  transform: scale(1.5);
}

.Web-icon{
 background-image: url(/advertisingimages/Website.png);
 background-size: cover;
 height: 30px;
 width:30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Web-icon:hover{
  transform: scale(1.5);
}

.Home-icon{
  background-image: url(/advertisingimages/Home.png);
  background-size: cover;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  width:30px;
}

.Home-icon:hover{
  transform: scale(1.5);
}
