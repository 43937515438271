  @import url('https://fonts.googleapis.com/css?family=Quattrocento&display=swap');

  .App-bodystylists{
    display: flex;
    width: 100vw;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    background-size: 100vh;
    margin-top: 70px;
  }

  .title{
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-family: 'Quattrocento', serif;
    color: rgb(145, 114, 71);
  }

  .header{
    font-size: 28px;
  }

  .stylesearch{
   width: 100%;
  }

  .stylediv{
    display: flex; flex-wrap: wrap;
    justify-content: space-around;
    width: 100vw;
    padding-left: 15px;
    padding-right: 15px;
  }
