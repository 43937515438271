@import url('https://fonts.googleapis.com/css?family=Quattrocento&display=swap');
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.aboutus{
  height: 100%;
  padding: 20px;

}

.background{
  height: 100%;
  width: 100%;
  background-image: url(/advertisingimages/Home.png);
  background-size: 360px 360px;
  background-position: center;
  background-repeat: no-repeat;
}

.abouttitle{
  padding: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
  font-size: 40px;
  background-color: rgba(240,240,240,.8);
}

.body{
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-weight: 300;
 color: rgb(12, 36, 14);
  position: relative;
  display: block;
  height: 100%;
  background-color: rgba(240,240,240,.8);
}

.body a{
  color: rgb(145, 114, 71);
}
.body a:hover{
  color: rgb(231, 195, 215);
}
