@import url('https://fonts.googleapis.com/css?family=Quattrocento&display=swap');

.App-bodyflorist{
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.floristsearch{
  width: 100%;
}

.title{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.floristdiv{
  display: flex; flex-wrap: wrap;
  justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}
