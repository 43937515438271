@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.title1{
  display: flex;
  justify-content: center;
  width: 100%;
  color: rgb(145,114,71);
}

.newlistpara{
  display: block;
  justify-content: center;
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-weight: 300;
  width: 90%;
  color: rgb(110,143,30);
  margin: 0 auto;
}

.Contact{
  display: flex;
  justify-content: center;
  width: 100%;
}

.infobox{
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.imageup{
  display: block;
  color: rgb(110,143,30);
  font-weight: 600;
  text-decoration: underline;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.busiselectbox{
  color: rgb(110,143,30);
  font-weight: 600;
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.enquiry{
  width: 100%;
  height: 250px;
  margin: 0 auto;
}

.form{
  display: block;
  justify-content: center;
  width: 90%;
}

.Send{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 80px;
  height: 35px;
  font-weight:500;
  font-size: 18px;
  color: rgb(12,36,14);
  background-color: rgb(235,207,207);
  margin: 0 auto;
}
