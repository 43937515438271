*{
  margin: 0;
  padding: 0;
}

.WholePage{
  margin:0;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  background-color:rgb(240, 240, 240);
  overflow: auto;
}

.Main{
  margin-top: 140px;
  min-height: 100vh;
  min-width: 100vw;
}

@media(max-width: 650px){
  .Main{
    margin-top: 60vw;
  }
}
