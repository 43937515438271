@import url('https://fonts.googleapis.com/css?family=Niconne&display=swap');

.Footer{
  height: 50px;
  display: flex;
  background-color:rgb(190, 190, 190);
  justify-content: center;
  z-index: 2;
  width: 100vw;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  bottom: 0;
  max-width: 100%;
  margin-top: 20px;


}

.Footer a{
  display: flex;
  font-family: 'Niconne', cursive;
  color: rgb(145, 114, 71);
  font-size: 1.2rem;
}

.Footer a:hover{
  color:rgb(231, 195, 215);
}
