.toolbar{
  width: 100vw;
  position: fixed;
  background-color: rgba(240,240,240);
  height: 140px;
  top: 0;
  left: 0;
  z-index: 2;
}

.page-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-family: serif;
}

.headerlogo{
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  align-items: center;
  height:140px;
  width: 560px;
}


.toolbar_navigation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.toolbar_toggle-button{
  display: flex;
  position: absolute;
  top: 0;
  left: 4%;
  align-items: center;
  height: 100%;
  width: 40px;
  justify-content: center;
  align-content: center;
}

.sociallogos{
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 4%;
  display: flex; flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.toolbar_navigation-items ul{
  list-style: none;
  margin:0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li{
  padding: 0 0.5rem;
}


.toolbar_navigation-items a{
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
  color: orange;
}


@media  (max-width: 650px){
.headerlogo{
  width: 100vw;
  height: 35vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar{
  display: block;
  position: fixed;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar_navigation{
  display:flex;flex-wrap: wrap;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.sociallogos{
  position: absolute;
  top: 34.5vw;
  justify-content: space-around;
  width: 90%;
  height: 12.5vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar_toggle-button{
  display: flex;
  position: absolute;
  top: 46.5vw;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 12.5vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
}
