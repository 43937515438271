@import url('https://fonts.googleapis.com/css?family=Pompiere&display=swap');

.Accommodation{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.BusHire{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.CakeMaker{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Caterers{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Celebrants{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Dressdesign{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Fireworks{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Florists{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.hairandmakeup{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Musicians{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Photographers{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Prophire{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Stylists{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Transport{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.Venues{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  justify-content: center;
  margin: 10px 5px;
}

.photo{
  display: flex;
  height: 166px;
  width: 255px;
  max-width: 85%;
  margin: 0 auto;
}

.icons{
  display: flex;
  height: 30px;
  width: 50%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;

}

.info{
  display: block;
  margin: 0 auto;
  padding: 10px 22.5px;
  max-width: 85%;
}
