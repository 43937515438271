@import url('https://fonts.googleapis.com/css?family=Niconne&display=swap');

.side-drawer{
  display: flex;
  height: 100vh;
  padding-left: 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0px 15px rgba(0,0,0,0.9);
  position: fixed;
  top: 0;
  left:0;
  width: 200px;
  max-width: 70%;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.7s ease-out;
}

.side-drawer.open{
  transform: translateX(0);
}

.side-drawer ul{
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.side-drawer a{
  color:rgb(145, 114, 71);
  text-decoration: none;
  font-size: 1.1rem;
}

.side-drawer li{
    font-family: 'Niconne', cursive;
}

.side-drawer li:hover{
  transform: scale(1.3);
}


.side-drawer a:hover,
.side-drawer a:active{
  color: rgb(231, 195, 215);
}
