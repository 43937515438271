@import url(https://fonts.googleapis.com/css?family=Pompiere&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css?family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css?family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Niconne&display=swap);
@import url(https://fonts.googleapis.com/css?family=Niconne&display=swap);
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  height: 100%;
}

.Fbook-icon{
 background-image: url(/advertisingimages/Facebook.png);
 background-size: cover;
 height: 30px;
 width:30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Fbook-icon:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.Insta-icon{
 background-image: url(/advertisingimages/Instagram.png);
 background-size: cover;
 height: 30px;
 width: 30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Insta-icon:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.Web-icon{
 background-image: url(/advertisingimages/Website.png);
 background-size: cover;
 height: 30px;
 width:30px;
 margin-left: 10px;
 margin-right: 10px;
}

.Web-icon:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.Home-icon{
  background-image: url(/advertisingimages/Home.png);
  background-size: cover;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  width:30px;
}

.Home-icon:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.Accommodation{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.BusHire{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.CakeMaker{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Caterers{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Celebrants{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Dressdesign{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Fireworks{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Florists{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.hairandmakeup{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Musicians{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Photographers{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Prophire{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Stylists{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Transport{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.Venues{
  display: block;
  width:300px;
  max-width: 85%;
  font-family: 'Pompiere', cursive;
  font-weight: 600;
  color: rgb(12, 36, 14);
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  background-color:rgb(190, 190, 190);
  border-style: groove;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 5px;
}

.photo{
  display: -webkit-flex;
  display: flex;
  height: 166px;
  width: 255px;
  max-width: 85%;
  margin: 0 auto;
}

.icons{
  display: -webkit-flex;
  display: flex;
  height: 30px;
  width: 50%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-justify-content: center;
          justify-content: center;

}

.info{
  display: block;
  margin: 0 auto;
  padding: 10px 22.5px;
  max-width: 85%;
}

.searchBox{
  width: 55%;
  height:5%;
  margin-left: 22.5%;
  margin-left: 22.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-style: solid;
  border-width: medium;
  border-color: rgb(145, 114, 71);
}

.App-bodyaccommodation{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.accommsearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  font-family: 'Quattrocento', serif;
  margin-top: 10px;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.accommdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodybushire{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.bussearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.busdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodycakemakers{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.cakesearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.cakediv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodycaterer{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.catersearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.caterdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodycelebrants{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.celebsearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.celebrantdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodydressdesigner{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.dresssearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.dressdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodyfireworks{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.title{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.firesearch{
 width: 100%;
}

.firediv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodyhairandmakeup{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.hairsearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.hairdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodyvenue{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.venuesearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.venuediv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodymusician{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.musicsearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.musicdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodyphotographer{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.title{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.headerphoto{
  font-size: 25px;
}

.photosearch{
 width: 100%;
}

.photographdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodyprophire{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.title{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.propsearch{
 width: 100%;
}

.propdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.App-bodytransport{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.title{
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.transsearch{
 width: 100%;
}

.transdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

  .App-bodystylists{
    display: -webkit-flex;
    display: flex;
    width: 100vw;
    min-height: 100vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
    background-size: 100vh;
    margin-top: 70px;
  }

  .title{
    display: -webkit-flex;
    display: flex;
    width: 100vw;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 10px;
    font-family: 'Quattrocento', serif;
    color: rgb(145, 114, 71);
  }

  .header{
    font-size: 28px;
  }

  .stylesearch{
   width: 100%;
  }

  .stylediv{
    display: -webkit-flex;
    display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100vw;
    padding-left: 15px;
    padding-right: 15px;
  }

.App-bodyflorist{
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-size: 100vh;
  margin-top: 70px;
}

.floristsearch{
  width: 100%;
}

.title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  margin-top: 10px;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
}

.header{
  font-size: 28px;
}

.floristdiv{
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
}

.aboutus{
  height: 100%;
  padding: 20px;

}

.background{
  height: 100%;
  width: 100%;
  background-image: url(/advertisingimages/Home.png);
  background-size: 360px 360px;
  background-position: center;
  background-repeat: no-repeat;
}

.abouttitle{
  padding: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  font-family: 'Quattrocento', serif;
  color: rgb(145, 114, 71);
  font-size: 40px;
  background-color: rgba(240,240,240,.8);
}

.body{
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-weight: 300;
 color: rgb(12, 36, 14);
  position: relative;
  display: block;
  height: 100%;
  background-color: rgba(240,240,240,.8);
}

.body a{
  color: rgb(145, 114, 71);
}
.body a:hover{
  color: rgb(231, 195, 215);
}

.title1{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  color: rgb(145,114,71);
}

.Contactpara{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-weight: 300;
  width: 90%;
  color: rgb(110,143,30);
  margin: 0 auto;
}

.Contact{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.infobox{
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.enquiry{
  width: 100%;
  height: 250px;
  margin: 0 auto;
}

.form{
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
  width: 90%;
}

.Send{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  width: 80px;
  height: 35px;
  font-weight:500;
  font-size: 18px;
  color: rgb(12,36,14);
  background-color: rgb(235,207,207);
  margin: 0 auto;
}

.title1{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  color: rgb(145,114,71);
}

.newlistpara{
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Vollkorn', serif;
  font-size: 24px;
  font-weight: 300;
  width: 90%;
  color: rgb(110,143,30);
  margin: 0 auto;
}

.Contact{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.infobox{
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.imageup{
  display: block;
  color: rgb(110,143,30);
  font-weight: 600;
  text-decoration: underline;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.busiselectbox{
  color: rgb(110,143,30);
  font-weight: 600;
  display: block;
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}

.enquiry{
  width: 100%;
  height: 250px;
  margin: 0 auto;
}

.form{
  display: block;
  -webkit-justify-content: center;
          justify-content: center;
  width: 90%;
}

.Send{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  width: 80px;
  height: 35px;
  font-weight:500;
  font-size: 18px;
  color: rgb(12,36,14);
  background-color: rgb(235,207,207);
  margin: 0 auto;
}

.carouselimages{
  display: block;
  width: 100%;
  height: 100%;
  position:relative;
  margin: 0 auto;
}

.wowtitle{
  display: -webkit-flex;
  display: flex;
  font-family: 'Dancing Script', cursive;
  font-size: 22px;
  color: rgb(145, 114, 71);
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  margin-bottom: 10px;
  margin-left: 17.5%;
  margin-right: 17.5%;
  width: 65%;
  z-index: 1;
}

.z-depth-1 {
  display: block;
  position: relative;
  width: 60%;
  height: 45vw;
  margin: 0 auto;
  margin-bottom: 0;
  z-index: 1;
}

.homepagecontainer{
  margin-top: 0px;
  min-height: 95vh;
  margin-bottom: 1px;
  width: 100%;
}

.privacypolicy{
  padding: 35px;
}

.TermsOfUse{
  padding: 35px;
}

.toggle-button{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus{
  outline: none;
}

.toggle-button_line{
  width: 30px;
  height: 2px;
  background: rgb(145, 114, 71);
}

.toolbar{
  width: 100vw;
  position: fixed;
  background-color: rgba(240,240,240);
  height: 140px;
  top: 0;
  left: 0;
  z-index: 2;
}

.page-title{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 2rem;
  font-family: serif;
}

.headerlogo{
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  align-items: center;
  height:140px;
  width: 560px;
}


.toolbar_navigation{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
}

.toolbar_toggle-button{
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 4%;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  width: 40px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}

.sociallogos{
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 4%;
  display: -webkit-flex;
  display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}


.toolbar_navigation-items ul{
  list-style: none;
  margin:0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.toolbar_navigation-items li{
  padding: 0 0.5rem;
}


.toolbar_navigation-items a{
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
  color: orange;
}


@media  (max-width: 650px){
.headerlogo{
  width: 100vw;
  height: 35vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar{
  display: block;
  position: fixed;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar_navigation{
  display:-webkit-flex;
  display:flex;-webkit-flex-wrap: wrap;flex-wrap: wrap;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.sociallogos{
  position: absolute;
  top: 34.5vw;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 90%;
  height: 12.5vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
.toolbar_toggle-button{
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 46.5vw;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 12.5vw;
  background-color:  rgba(240,240,240);
  z-index: 2;
}
}

.side-drawer{
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  padding-left: 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0px 15px rgba(0,0,0,0.9);
  position: fixed;
  top: 0;
  left:0;
  width: 200px;
  max-width: 70%;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.7s ease-out;
  transition: transform 0.7s ease-out;
  transition: transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
}

.side-drawer.open{
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.side-drawer ul{
  height: 100%;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.side-drawer a{
  color:rgb(145, 114, 71);
  text-decoration: none;
  font-size: 1.1rem;
}

.side-drawer li{
    font-family: 'Niconne', cursive;
}

.side-drawer li:hover{
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}


.side-drawer a:hover,
.side-drawer a:active{
  color: rgb(231, 195, 215);
}

.backdrop{
  position: fixed;
  height: 100%;
  width: 100%;
  background:rgba(150, 150, 150, 0.5);
  z-index: 100;
  top: 0;
  left:0;
}

.Footer{
  height: 50px;
  display: -webkit-flex;
  display: flex;
  background-color:rgb(190, 190, 190);
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 2;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  max-width: 100%;
  margin-top: 20px;


}

.Footer a{
  display: -webkit-flex;
  display: flex;
  font-family: 'Niconne', cursive;
  color: rgb(145, 114, 71);
  font-size: 1.2rem;
}

.Footer a:hover{
  color:rgb(231, 195, 215);
}

*{
  margin: 0;
  padding: 0;
}

.WholePage{
  margin:0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  align-items: center;
  min-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  background-color:rgb(240, 240, 240);
  overflow: auto;
}

.Main{
  margin-top: 140px;
  min-height: 100vh;
  min-width: 100vw;
}

@media(max-width: 650px){
  .Main{
    margin-top: 60vw;
  }
}

