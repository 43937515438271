@import url('https://fonts.googleapis.com/css?family=Dancing+Script:700&display=swap');

.carouselimages{
  display: block;
  width: 100%;
  height: 100%;
  position:relative;
  margin: 0 auto;
}

.wowtitle{
  display: flex;
  font-family: 'Dancing Script', cursive;
  font-size: 22px;
  color: rgb(145, 114, 71);
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
  margin-left: 17.5%;
  margin-right: 17.5%;
  width: 65%;
  z-index: 1;
}

.z-depth-1 {
  display: block;
  position: relative;
  width: 60%;
  height: 45vw;
  margin: 0 auto;
  margin-bottom: 0;
  z-index: 1;
}

.homepagecontainer{
  margin-top: 0px;
  min-height: 95vh;
  margin-bottom: 1px;
  width: 100%;
}
